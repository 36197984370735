<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import CampaignServices from "@/services/CampaignServices";
import SMSUtils from "@/helpers/SMSUtils";
import { mapGetters } from "vuex";
import axios from "axios";
import { BASE_URL } from "@/helpers/constants";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Lancer un événement",
  },
  components: {
    Layout,
    PageHeader,
    FlatPickr: flatPickr,
  },
  data() {
    return {
      title: "Nouvelle campagne SMS",
      items: [
        {
          text: "GICAM",
          href: "/",
        },
        {
          text: "Campagnes SMS",
          href: "/",
        },
        {
          text: "Ajouter",
          active: true,
        },
      ],

      sheduled: false,

      dateTimeConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },

      cibles: [],

      searchQuery: "",

      submitting: false,

      fields: {
        name: null,
        description: "",
        message: "",
        campaign_start_date: null,
        sender_id: 1,
      },

      form: {
        name: "",
        phone: "",
        start_date: null,
      },

      new_campaign: null,
    };
  },

  mounted() {
    // user profile img file upload

    this.loadDatas();

    document.querySelectorAll(".form-steps").forEach(function (form) {
      // next tab
      form.querySelectorAll(".nexttab").forEach(function (nextButton) {
        var tabEl = form.querySelectorAll('button[data-bs-toggle="pill"]');
        tabEl.forEach(function (item) {
          item.addEventListener("show.bs.tab", function (event) {
            event.target.classList.add("done");
          });
        });
        nextButton.addEventListener("click", function () {
          var nextTab = nextButton.getAttribute("data-nexttab");
          document.getElementById(nextTab).click();
        });
      });

      //Pervies tab
      form.querySelectorAll(".previestab").forEach(function (prevButton) {
        prevButton.addEventListener("click", function () {
          var prevTab = prevButton.getAttribute("data-previous");
          var totalDone = prevButton
            .closest("form")
            .querySelectorAll(".custom-nav .done").length;
          for (var i = totalDone - 1; i < totalDone; i++) {
            const elem = prevButton
              .closest("form")
              .querySelectorAll(".custom-nav .done")[i];
            elem ? elem.classList.remove("done") : "";
          }
          document.getElementById(prevTab).click();
        });
      });

      // Step number click
      var tabButtons = form.querySelectorAll('button[data-bs-toggle="pill"]');
      tabButtons.forEach(function (button, i) {
        button.setAttribute("data-position", i);
        button.addEventListener("click", function () {
          var getProgreebar = button.getAttribute("data-progressbar");
          if (getProgreebar) {
            var totallength =
              document
                .getElementById("custom-progress-bar")
                .querySelectorAll("li").length - 1;
            var current = i;
            var percent = (current / totallength) * 100;
            document
              .getElementById("custom-progress-bar")
              .querySelector(".progress-bar").style.width = percent + "%";
          }
          form.querySelectorAll(".custom-nav .done").length > 0
            ? form
                .querySelectorAll(".custom-nav .done")
                .forEach(function (doneTab) {
                  doneTab.classList.remove("done");
                })
            : "";
          for (var j = 0; j <= i; j++) {
            tabButtons[j].classList.contains("active")
              ? tabButtons[j].classList.remove("done")
              : tabButtons[j].classList.add("done");
          }
        });
      });
    });
  },

  methods: {
    saStartLoading(title) {
      // Swal loading animation and indicator
      Swal.fire({
        title: title || "Traitement du document...",
        // Bootstrap  spinner
        html: `<div class="spinner-border text-primary" role="status"></div>`,
        allowOutsideClick: false,
        showConfirmButton: false,
      });
    },
    saStopLoading() {
      Swal.close();
    },
    handleUploadDoc($event) {
      const files = $event.target.files;
      if (files[0]) {
        this.processFile(files[0]);
      }
    },
    processFile(file) {
      this.loading = true;
      this.saStartLoading();
      const formData = new FormData();
      formData.append("document", file);

      return axios
        .post(BASE_URL + "/campaigns/targets/verify", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(({ data }) => {
          console.log(data);

          this.loading = false;
          const docs = data.data.targets;

          docs.forEach((doc) => {
            this.cibles.push(doc);
          });

          this.loaded = true;
          this.saStopLoading();

          this.$saSuccess(
            `${docs.length < 10 ? "0" : ""}${docs.length} élément${
              docs.length > 1 ? "s" : ""
            } ${docs.length > 1 ? "ont" : "a"} été trouvé${
              docs.length > 1 ? "s" : ""
            }`,
            ""
          );
        })
        .catch((error) => {
          this.saStopLoading();
          this.$saError(
            "Oups !",
            error.response.data.message ||
              "Une erreur est survenue lors du traitement du document"
          );
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadDatas() {
      if (this.$route.query.mode == "clone") {
        if (!this.currentCampaign?.id) return;
        this.fields = {
          name: this.currentCampaign.name,
          description: this.currentCampaign.description,
          message: this.currentCampaign.message,
          campaign_start_date: this.currentCampaign.diffuse_at,
          sender_id: this.currentCampaign.gicam_campaign_sender_id,
        };

        this.sheduled = this.currentCampaign.sheduled;

        this.cibles = this.currentCampaign.targets.map((t) => ({
          name: t.target_name,
          phone: t.target_phone,
        }));
      }
    },
    handleGoToDetails(campaign) {
      this.$store.dispatch("campaigns/setCurrentCampaign", campaign);
      this.$router.push({
        name: "campagnes-sms-details",
        params: { campaignId: campaign.id },
      });
    },
    handleRemoveItem(index) {
      this.cibles.splice(index, 1);
    },
    handleAddItem() {
      if (!this.form.phone) return;
      this.cibles.push(this.form);
      this.form = { name: "", phone: "" };
    },
    async handleSubmit() {
      this.submitting = true;
      await CampaignServices.create({
        ...this.fields,
        campaign_start_date: this.sheduled
          ? this.fields.campaign_start_date
          : null,
        sheduled: this.sheduled,
        targets: this.cibles,
      })
        .then(async ({ data }) => {
          if (data.error === false) {
            this.new_campaign = data.data.campaign;

            this.fields = {
              name: null,
              description: "",
              message: "",
              campaign_start_date: null,
            };

            if (!this.sheduled) {
              await this.handleDiffuse();
            }

            window.document.querySelector("#submit-button").click();
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async handleDiffuse() {
      this.submitting = true;
      await CampaignServices.diffuse(this.new_campaign.id)
        .then(({ data }) => {
          if (data.error === false) {
            window.document.querySelector("#submit-button").click();
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },

  computed: {
    ...mapGetters("system", ["senders"]),
    ...mapGetters("campaigns", ["currentCampaign"]),
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.cibles.filter((data) => {
          return (
            data.name?.toLowerCase().includes(search) ||
            data.phone?.toLowerCase().includes(search)
          );
        });
      } else {
        return this.cibles;
      }
    },
    smsCount() {
      return SMSUtils.countLength(this.fields.message ?? "");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="d-flex align-items-center mb-3">
        <button
          type="button"
          class="btn btn-light btn-xl"
          @click="$router.replace('/campaign/sms')"
        >
          <i class="mdi mdi-arrow-left"></i> Retour
        </button>
      </div>

      <!--end col-->
      <div class="col-xxl-12 mt-4">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="mb-0">
              Informations de la campagne
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <form action="#" class="form-steps" autocomplete="off">
              <div class="step-arrow-nav mb-4">
                <ul
                  class="nav nav-pills custom-nav nav-justified"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link active"
                      id="steparrow-gen-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-gen-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-gen-info"
                      aria-selected="true"
                    >
                      Ajout des cibles
                    </b-button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-sms-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-sms-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-sms-info"
                      aria-selected="false"
                    >
                      Config. SMS
                    </b-button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-lancement-info-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-lancement-info"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-lancement-info"
                      aria-selected="false"
                    >
                      Lancement
                    </b-button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <b-button
                      variant="link"
                      class="nav-link"
                      id="steparrow-finish-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#steparrow-finish"
                      type="button"
                      role="tab"
                      aria-controls="steparrow-finish"
                      aria-selected="false"
                    >
                      Terminé
                    </b-button>
                  </li>
                </ul>
              </div>

              <div class="tab-content">
                <div
                  class="tab-pane fade show active"
                  id="steparrow-gen-info"
                  role="tabpanel"
                  aria-labelledby="steparrow-gen-info-tab"
                >
                  <b-row>
                    <b-col md="6">
                      <div style="padding: 20px">
                        <div class="col-lg-12">
                          <div class="row mb-4">
                            <div class="col-sm">
                              <div class="d-flex justify-content-sm-end">
                                <div class="search-box ms-2">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Rechercher..."
                                    v-model="searchQuery"
                                  />
                                  <i class="ri-search-line search-icon"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex align-items-center mb-4">
                          <h5 class="card-title flex-grow-1">
                            Liste des cibles

                            <span
                              class="badge bg-soft-primary text-primary ms-2"
                              v-if="searchQuery"
                            >
                              {{ resultQuery.length }} résultat(s) pour "{{
                                searchQuery
                              }}" sur
                              {{ cibles.length }}
                            </span>
                          </h5>

                          <div class="ms-2">
                            <label
                              for="file-upload"
                              class="btn btn-warning"
                              aria-expanded="true"
                            >
                              <i
                                class="ri-file-upload-fill me-2 align-bottom text-muted"
                              ></i>
                              Importer
                            </label>
                            <input
                              class="d-none"
                              id="file-upload"
                              type="file"
                              @change="handleUploadDoc"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="table-responsive table-card">
                              <table
                                class="table table-borderless align-middle"
                                style="font-size: 13px"
                              >
                                <thead class="table-light">
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Nom</th>
                                    <th scope="col" class="text-center">
                                      Numéro de téléphone
                                    </th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(doc, index) of resultQuery || []"
                                    :key="index"
                                  >
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ doc.name }}</td>
                                    <td class="text-center">
                                      {{ doc.phone }}
                                    </td>
                                    <td>
                                      <div class="dropdown">
                                        <a
                                          href="javascript:void(0);"
                                          class="btn btn-soft-secondary btn-sm btn-icon"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="true"
                                        >
                                          <i class="ri-more-fill"></i>
                                        </a>
                                        <ul
                                          class="dropdown-menu dropdown-menu-end"
                                        >
                                          <li>
                                            <a
                                              class="dropdown-item"
                                              @click.prevent="
                                                handleRemoveItem(index)
                                              "
                                              ><i
                                                class="ri-delete-bin-6-line me-2 align-bottom text-muted"
                                              ></i>
                                              Effacer
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col md="6">
                      <b-row>
                        <b-col md="12">
                          <div class="mb-3">
                            <label
                              class="form-label"
                              for="steparrow-gen-info-email-input"
                            >
                              Nom
                            </label>
                            <input
                              v-model="form.name"
                              type="text"
                              class="form-control"
                              id="steparrow-gen-info-email-input"
                              required
                            />
                          </div>
                        </b-col>

                        <b-col md="12">
                          <div class="mb-3">
                            <label
                              class="form-label"
                              for="steparrow-gen-info-email-input"
                            >
                              Téléphone
                            </label>
                            <input
                              v-model="form.phone"
                              type="text"
                              class="form-control"
                              id="steparrow-gen-info-email-input"
                              required
                            />
                          </div>
                        </b-col>
                        <b-col md="12">
                          <b-button
                            type="button"
                            variant="primary"
                            class="ms-auto"
                            @click.prevent="handleAddItem"
                          >
                            <span> Ajouter </span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <b-button
                      type="button"
                      variant="primary"
                      class="btn-label right ms-auto nexttab nexttab"
                      data-nexttab="steparrow-sms-info-tab"
                      ><i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>
                      Suivant
                    </b-button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="steparrow-sms-info"
                  role="tabpanel"
                  aria-labelledby="steparrow-sms-info-tab"
                >
                  <div>
                    <b-row>
                      <b-col lg="12">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="des-sender_name-name-input"
                          >
                            Nom d'envoie
                          </label>
                          <select
                            class="form-select"
                            id="des-sender_name-name-input"
                            v-model="fields.sender_id"
                            required
                          >
                            <option
                              v-for="sender of senders"
                              :key="sender.id"
                              :value="sender.id"
                            >
                              {{ sender.name }}
                            </option>
                          </select>
                          <!-- <input
                            v-model="fields.name"
                            class="form-control"
                            id="des-info-name-input"
                            rows="3"
                            type="text"
                            required
                          /> -->
                        </div>
                      </b-col>
                      <b-col lg="12">
                        <div class="mb-3">
                          <label class="form-label" for="des-info-name-input">
                            Intitulé de la campagne
                          </label>
                          <input
                            v-model="fields.name"
                            class="form-control"
                            id="des-info-name-input"
                            rows="3"
                            type="text"
                            required
                          />
                        </div>
                      </b-col>
                      <b-col lg="12">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="des-info-description-input"
                            >
                              Contenu du SMS
                            </label>
                            <span class="text-warning">
                              {{ smsCount.remaining }} ({{ smsCount.messages }})
                            </span>
                          </div>
                          <textarea
                            v-model="fields.message"
                            class="form-control"
                            id="des-info-description-input"
                            rows="3"
                            required
                          ></textarea>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="d-flex align-items-start gap-3 mt-4">
                    <b-button
                      type="button"
                      variant="primary"
                      class="btn-label right ms-auto nexttab nexttab"
                      data-nexttab="steparrow-lancement-info-tab"
                      ><i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>
                      Suivant
                    </b-button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="steparrow-lancement-info"
                  role="tabpanel"
                  aria-labelledby="steparrow-lancement-info-tab"
                >
                  <div>
                    <b-row>
                      <b-col md="6">
                        <div style="padding: 20px">
                          <div class="col-lg-12">
                            <div class="row mb-4">
                              <div class="col-sm">
                                <div class="d-flex justify-content-sm-end">
                                  <div class="search-box ms-2">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Rechercher..."
                                      v-model="searchQuery"
                                    />
                                    <i class="ri-search-line search-icon"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <div class="table-responsive table-card">
                                <table
                                  class="table table-borderless align-middle"
                                  style="font-size: 13px"
                                >
                                  <thead class="table-light">
                                    <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Nom</th>
                                      <th scope="col" class="text-center">
                                        Numéro de téléphone
                                      </th>
                                      <th scope="col">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(doc, index) of resultQuery || []"
                                      :key="index"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>{{ doc.name }}</td>
                                      <td class="text-center">
                                        {{ doc.phone }}
                                      </td>
                                      <td>
                                        <div class="dropdown">
                                          <a
                                            href="javascript:void(0);"
                                            class="btn btn-soft-secondary btn-sm btn-icon"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="true"
                                          >
                                            <i class="ri-more-fill"></i>
                                          </a>
                                          <ul
                                            class="dropdown-menu dropdown-menu-end"
                                          >
                                            <li>
                                              <a
                                                class="dropdown-item"
                                                @click.prevent="
                                                  handleRemoveItem(index)
                                                "
                                                ><i
                                                  class="ri-delete-bin-6-line me-2 align-bottom text-muted"
                                                ></i>
                                                Effacer
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="6">
                        <h5 class="text-center">Message</h5>

                        <!-- Dark Alert -->
                        <b-alert show class="mb-0" variant="dark">
                          {{ fields.message }}
                        </b-alert>

                        <div class="d-flex flex-column mt-4">
                          <h5 class="text-center">
                            Programmation de la diffusion
                          </h5>

                          <!-- Switches Color -->
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="SwitchCheck1"
                              :checked="sheduled"
                              v-model="sheduled"
                            />
                            <label class="form-check-label" for="SwitchCheck1">
                              Diffuser plus tard
                            </label>
                          </div>

                          <div class="mt-2" v-if="sheduled">
                            <label class="form-label" for="des-info-date-input">
                              Date de diffusion
                            </label>
                            <!-- <input
                              v-model="fields.campaign_start_date"
                              type="datetime-local"
                              class="form-control"
                              required
                            /> -->
                            <FlatPickr
                              v-model="fields.campaign_start_date"
                              id="des-info-date-input"
                              :config="dateTimeConfig"
                              class="form-control"
                            ></FlatPickr>
                          </div>

                          <b-button
                            v-if="!sheduled"
                            type="button"
                            variant="primary"
                            class="btn-label right mt-3"
                            @click.prevent="handleSubmit"
                            :disabled="submitting"
                          >
                            <i
                              class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                            ></i>

                            <span
                              v-if="submitting"
                              class="spinner-border spinner-border-sm text-secondary"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span v-else> Lancer maintenant </span>
                          </b-button>
                          <b-button
                            v-else
                            type="button"
                            variant="warning"
                            class="btn-label right mt-3"
                            @click.prevent="handleSubmit"
                            :disabled="submitting"
                          >
                            <i
                              class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                            ></i>
                            <span
                              v-if="submitting"
                              class="spinner-border spinner-border-sm text-primary"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span v-else> Enregistrer </span>
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="d-flex justify-content-end mt-4 gap-3">
                    <button
                      id="submit-button"
                      class="d-none nexttab nexttab"
                      data-nexttab="steparrow-finish-tab"
                    ></button>
                  </div>
                </div>

                <div
                  class="tab-pane fade"
                  id="steparrow-finish"
                  role="tabpanel"
                >
                  <div class="text-center">
                    <div class="avatar-md mt-5 mb-4 mx-auto">
                      <div
                        class="avatar-title bg-light text-success display-4 rounded-circle"
                      >
                        <i class="ri-checkbox-circle-fill"></i>
                      </div>
                    </div>
                    <h5>Parfait !</h5>
                    <p class="text-muted">
                      Votre diffusion a été
                      {{ sheduled ? "programmée" : "lancée" }}. Vous pouvez
                      maintenant la gérer.
                    </p>
                    <b-button
                      type="button"
                      variant="primary"
                      class="mt-4 btn-label right ms-auto"
                      @click.prevent="handleGoToDetails(new_campaign)"
                    >
                      <i
                        class="ri-arrow-right-line label-icon align-middle fs-16 ms-2"
                      ></i>
                      Gérer ma campagne
                    </b-button>
                  </div>
                </div>
              </div>
            </form>
          </b-card-body>
        </b-card>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
